import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';
import { CoreHelperService } from '../providers/core-helper/core-helper.service';
import { UserManagementService } from '../modules/auth/administration/user-management/user-management.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";

@Injectable({
  providedIn: 'root',
})

export class NotificationHubService {

  private connection: signalR.HubConnection = new signalR.HubConnectionBuilder()
    .withUrl(environment.api + '/notification-hub')
    .configureLogging(signalR.LogLevel.Error)
    .withAutomaticReconnect()
    .build();

  public notificationObject = new Subject<number>();
  public storageUpdate = new Subject<boolean>();

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private coreHelperService: CoreHelperService,
    private _userManagementService: UserManagementService,
    private dialogService: DialogService,
  ) {
    this.connection.on('OnNotification', (res) => {
      if (res.isActive) {
        if (res.userId == this.coreHelperService.getUserId()) {
          let oktaToken = localStorage.getItem('oktaToken');
          let userInfo = this.coreHelperService.getLoggedInUserDetail();
          let userName = this.coreHelperService.isStringEmptyOrWhitespace(userInfo.userName) ? userInfo.emailId : userInfo.userName
          let requestParam: any = {
            userName: userName,
            OktaTokenId: oktaToken,
            isLoginRequest: false,
          };
          this._userManagementService
            .getLoginDetailByUserName(requestParam)
            .subscribe((res) => {
              if (res.apiStatus) {
                localStorage.removeItem('UserDetail');
                localStorage.setItem('UserDetail', JSON.stringify(res.apiData[0]));
                window.location.reload();
              }
            });
        }
      }
      else {
        if (res.userId == this.coreHelperService.getUserId()) {
          this.coreHelperService.callHeaderNotification();
        }
      }
    });

    //When User permission get changed
    this.connection.on('OnUserPermissionChanged', async (res) => {
      let currentUserId = this.coreHelperService.getUserId();
      if(res.isUserPermissionChanged && res.userId == currentUserId){
        this.showLogoutPopup("Your permission was updated. Please log in again.");
      };
    });

    //When user Role get changed
    this.connection.on('OnUserRoleChanged', async (res) => {
      let currentUserId = this.coreHelperService.getUserId();
      if(res.isUserPermissionChanged && res.userId == currentUserId){
        this.showLogoutPopup("Your Role was updated. Please log in again.");
      };
    });
  }

  async StartConnection() {
    try {
      if (this.connection.state == signalR.HubConnectionState.Disconnected) {
        await this.connection.start();
      }
    } catch {
      err => console.log('SignalR Startup Error:: ' + err);
     }
  }

  async StopConnection() {
    await this.connection.stop();
  }

  OnNotification(): Observable<number> {
    return this.notificationObject.asObservable();
  }

  
  async doLogout(){
    localStorage.removeItem('token');
    localStorage.removeItem('cereus-storage');
    localStorage.removeItem('cereus-token-storage');
    localStorage.removeItem('cereus-perm-storage');
    localStorage.clear();
    await this.oktaAuth.signOut();
  }

  public showLogoutPopup(message: string): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "You have been logged out",
      content: message,
      actions: [{ text: "Close", themeColor: "primary"}],
      width: 450,
      height: 200,
      minWidth: 250,
      cssClass: ".k-window-titlebar{ background-color: #05B5E4; color: white;}",
    });
    dialog.result.subscribe((result) => {
      this.doLogout();
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text === "Close"){
          this.doLogout();
        }
      }
    });
  }
}
