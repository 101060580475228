import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidatorFn } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IdNameModel } from 'src/app/common/service/topativity.classes';
import { ConstantKey, LoginServices } from './core-helper.classes';
import { ApplicationMenuSections } from 'src/app/common/enum/app-menu-sections';
import { ModuleMasterIds } from 'src/app/common/enum/module-master';
import { ModuleFeatureIds } from 'src/app/common/enum/module-feature';
import { RoleType } from 'src/app/common/enum/role-type.enum';
declare var bootstrap: any;

export enum ServiceColorCodeId {
  NotConfirmedColorId = 1,
  ConfirmedColorId = 2,
  BilledColorId = 3,
  BilledIssueColorId = 4,
  DeniedColorId = 5,
  ChangeServiceColorId = 6,
  PaidColorId = 7,
  RefundColorId = 8,
  DarkGreenPaidColorId = 9,
  VoidColorId = 10
}
export enum departmentCodeId {
  ThirdPartyNegotiations = 1,
  RefundRequests = 2,
  RevenueCycleManagement = 3,
  MedicalRecords = 4,
  VerificationofBenefits = 5,
  UtilizationReview = 6,
  Billing = 7,
  PaymentPosting = 8,
  Credentialing = 9,
  AccountManagement = 10,
  Facility = 11,
  OPs = 12,
}

@Injectable({
  providedIn: 'root'
})

export class CoreHelperService {

  private permissionList: any = [];

  // used for file upload validation
  imageSize = 3;
  allowedExtension = ["png", "jpeg", "jpg", "gif"];
  previousStartWeek: any;

  //departmnet constnt name
  VOB = 'verification of benefits';
  UR = 'utilization review';
  Billing = 'billing';
  RCM = 'revenue cycle management';
  MedicalRecord = "medical records";
  Payment = "payment posting";
  //PageSize

  pageSize = 200;
  tenPageSize = 10;

  //custom color of donut chart
  chartDefaultV4Colors: string[] = [
    "#b8f0fe",
    "#98c6dc",
    "#02b8e3",
    "#4297bf",
    "#9bc2e6",
  ];

  isShowHeader: boolean | null = true;
  frontOfficeLogin: boolean = true;
  headerTitle = new Subject<string>();
  callHeader = new Subject<boolean>();
  private viewWarehouseDetails = new BehaviorSubject<Object>(false);
  warehouseDetailsData = this.viewWarehouseDetails.asObservable();

  private sideBarLinkActive = new BehaviorSubject<string>("");
  sidebarActiveModuleName = this.sideBarLinkActive.asObservable();

  LoginServiceInfo: LoginServices;

  public correctedClaimReasonList: Array<{ text: string; value: number }> = [
    { text: "Incorrect DOS billed", value: 1 },
    { text: "Incorrect LOC billed", value: 2 },
    { text: "Coding", value: 3 },
    { text: "Patient demographics updated (policy ID#, policy GRP#, patient DOB, etc.)", value: 4 },
    { text: "Auth# updated", value: 5 },
    { text: "Interim charges combined to reflect admit through DC", value: 6 },
    { text: "Incorrect service location", value: 7 },
    { text: "Incorrect provider", value: 8 },
    { text: "Misc (not listed elsewhere)", value: 9 },
  ];

  public voidClaimReasonList: Array<{ text: string; value: number }> = [
    { text: "Claim/ service billed in error", value: 1 },
    { text: "Misc (not listed elsewhere)", value: 2 },
  ];

  constructor(private titleService: Title, private route: Router) { }

  public passHeaderTitle(headerTitle: string) {
    this.headerTitle.next(headerTitle);
  }
  public updateTabData(headerTitle: string) {
    this.headerTitle.next(headerTitle);
  }
  public callHeaderNotification() {
    this.callHeader.next(true);
  }
  //patterns
  Passwordpattern: string = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$";
  EmailPattern: string = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$";
  SpacePattern: string = "^[a-zA-Z0-9_-]+$";
  //size
  fileSize: any = "10485760";

  //
  getTitle() {
    return localStorage.getItem('title')
  }

  setRootData = () => {
    this.viewWarehouseDetails.next(true);
  }

  setSidebarModuleName = (sidebarModuleName: string) => {
    this.sideBarLinkActive.next(sidebarModuleName);
  }

  setBrowserTabTitle = (title: string) => {
    this.titleService.setTitle(title);
  }

  isNullOrUndefined<T>(tObj: T): boolean {
    return tObj === null || tObj === undefined;
  }

  getPreviosFouryear() {
    let dateArr = new Array<IdNameModel>();
    let d = new Date();
    for (let index = 0; index < 4; index++) {
      let arr = new IdNameModel();
      arr.code = d.getFullYear() - index;
      arr.id = index;
      dateArr.push(arr)
    }
    return dateArr;
  }

  isStringEmptyOrWhitespace(stringToParse: string) {
    return this.isNullOrUndefined(stringToParse) || stringToParse.trim() === '' || stringToParse.trim() === 'null' || stringToParse.trim() === 'undefined';
  }

  isStringNullOrUndefined(stringToParse: string) {
    return this.isNullOrUndefined(stringToParse) || stringToParse === '';
  }
  isArrayEmpty<T>(tArr: T[]): boolean {
    return this.isNullOrUndefined(tArr) || tArr.length <= 0;
  }

  getShortedText = (text: string, limit: number) => {
    if (!this.isNullOrUndefined(limit)) {
      return text.length > limit ? text.substring(0, limit) + '...' : text;
    }
    return text.length > ConstantKey.STRINGLIMITSHORT ? text.substring(0, ConstantKey.STRINGLIMITSHORT) + '...' : text;
  }

  removeAllWhiteSpaces = (text: string) => {
    return text.replace(/\s/g, '');
  }

  snakeCaseToLowerCase = (text: string) => {
    return text.replace(/\_/g, '');
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        const totalSelected = formArray.controls.length;
        return totalSelected >= min ? null : { notSelected: true };
      }

      throw new Error('formArray is not an instance of FormArray');
    };

    return validator;
  }
  maxSelectedCheckboxes(max: number) {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        const totalSelected = formArray.controls.length;
        return totalSelected > max ? { maxInvalid: true } : null;
      }

      throw new Error('formArray is not an instance of FormArray');
    };

    return validator;
  }

  updateUserAgreementInfo(){
    var keyData = this.GetDecodedValue(localStorage.getItem('cereus-storage'));
    var appStorage = JSON.parse(keyData);
    appStorage.isAgreed = 1;

    localStorage.removeItem('cereus-storage');
    localStorage.setItem('cereus-storage', this.GetEncodedValue(JSON.stringify(appStorage)));
  }

  getStorageItemsByName(keyName: string): any {
    var keyData = this.GetDecodedValue(localStorage.getItem('cereus-storage'));
    var appStorage = JSON.parse(keyData);
    switch (keyName) {
      case "userId":
        return appStorage.userId;
      case "firstName":
        return appStorage.firstName;
      case "lastName":
        return appStorage.lastName;
      case "userName":
        return appStorage.userName;
      case "emailId":
        return appStorage.emailId;
      case "domainId":
        return appStorage.domainId;
      case "facilityId":
        return appStorage.facilityId;
      case "departmentId":
        return appStorage.departmentId;
      case "facilityName":
        return appStorage.facilityName;
      case "profileImage":
        return appStorage.profileImage;
      case "isAgreed":
        return appStorage.isAgreed;
      case "roleName":
        return appStorage.roleName;
      case "roleTypeId":
        return appStorage.roleTypeId;
      case "all":
        return appStorage;
    }
  }

  getFacilityName = () => {
    return this.getStorageItemsByName("facilityName");
  }

  getUserId = () => {
    return this.getStorageItemsByName("userId");
  }

  getUserFullName = () => {
    let userFirstName = this.getStorageItemsByName("firstName");
    let userLastName = this.getStorageItemsByName("lastName");
    return (userFirstName + ' ' + userLastName);
  }

  getOpsDepartmentId = () => {
    return this.getStorageItemsByName("departmentId");
  }

  getUserDomain() {
    return this.getStorageItemsByName("domainId");
  }

  getLoggedInUserDomain() {
    return this.getUserDomain();
  }

  getFacilityId = () => {
    let userDomainId = this.getUserDomain();
    let facilityId = this.getStorageItemsByName("facilityId");
    return (userDomainId == 0) ? 0 : facilityId;
  }

  getLoggedInUserImagePath = () => {
    return this.getStorageItemsByName("profileImage");
  }

  getProfileName = () => {
    return this.getUserFullName();
  }

  isHideFacility = () => {
    return this.getFacilityId() > 0 ? true : false;
  }

  getUserRoleName(){
    return this.getStorageItemsByName("roleName");
  }


  //Permission Data
  getPermissionStorageData(){
    if(localStorage.getItem("cereus-perm-storage") == null){
      return JSON.parse("{}");
    }
    var permData = this.GetDecodedValue(localStorage.getItem('cereus-perm-storage'));
    var returnValue = JSON.parse(permData);
    return returnValue;
  }

  //Authorized URL
  checkModulePermission(paramURL: string) {
    var myPermission = this.getPermissionStorageData();
    if (myPermission != undefined && myPermission.length > 0){
      let returnPermission = myPermission.filter(r => r.navigationRoute === paramURL);
      return (returnPermission.length>0);
    }
    return true;
  }

  //Facility
  getFacilityModulePermission() {
    var myPermission = this.getPermissionStorageData();
    let appPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Resource && r.moduleMasterId === ModuleMasterIds.FacilityMaster);
    return appPermission;
  }

  getFacilityModuleFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let appPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Resource && 
          (r.moduleMasterId === ModuleMasterIds.FacilityMaster || r.moduleMasterId === ModuleMasterIds.CarrierMaster) && r.moduleElementTransId > 0);
    return appPermission;
  }

  //Patients
  gePatientsModulePermission() {
    var myPermission = this.getPermissionStorageData();
    let appPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Resource && r.moduleMasterId === ModuleMasterIds.PatientMaster);
    return appPermission;
  }

  getPatientsModuleFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let appPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Resource && r.moduleMasterId === ModuleMasterIds.PatientMaster && r.moduleElementTransId > 0);
    return appPermission;
  }

  //Document Vault
  geDocumentVaultModulePermission() {
    var myPermission = this.getPermissionStorageData();
    let appPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Resource && r.moduleMasterId === ModuleMasterIds.DocumentVault);
    return appPermission;
  }

  getDocumentVaultModuleFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let appPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Resource && r.moduleMasterId === ModuleMasterIds.DocumentVault && r.moduleElementTransId > 0);
    return appPermission;
  }

  //Census
  getCensusModulePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.Census);
    return returnPermission;
  }

  //VOB
  getVOBModulePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.VOB && r.moduleElementTransId === 0);
    return returnPermission;
  }

  getVOBModuleFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.VOB && r.moduleElementTransId > 0);
    return returnPermission;
  }


  //UR - Authorization
  getURModulePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.UR && r.moduleElementTransId === 0);
    return returnPermission;
  }

  getURModuleFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.UR && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Billing
  getBillingModulePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.Billing && r.moduleElementTransId === 0);
    return returnPermission;
  }

  getBillingModuleFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.Billing && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //RCM - Revenue Collection
  getRCMModulePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.RCM && r.moduleElementTransId === 0);
    return returnPermission;
  }

  getRCMModuleFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.RCM && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Medical Records
  getMedicalRecordModulePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.MedicalRecord && r.moduleElementTransId === 0);
    return returnPermission;
  }

  getMedicalRecordModuleFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.MedicalRecord && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Payments
  getPaymentsModulePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.Payments && r.moduleElementTransId === 0);
    return returnPermission;
  }

  getPaymentPermission_ERA() {
    var myPermission = this.getPermissionStorageData();
    let filteredList = myPermission.filter(r => r.moduleFeatureMasterId === ModuleFeatureIds.Payments_ManageERAs && r.isEdit === 1);
    return (filteredList != undefined && filteredList != null && filteredList.length > 0);
  }

  getPaymentPermission_AddTask() {
    var myPermission = this.getPermissionStorageData();
    let filteredList = myPermission.filter(r => r.moduleFeatureMasterId === ModuleFeatureIds.Payments_AddTask && r.isEdit === 1);
    return (filteredList != undefined && filteredList != null && filteredList.length > 0);
  }

  //TPN - Third Party Negotiations
  getTPNModulePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.TPN && r.moduleElementTransId === 0);
    return returnPermission;
  }

  getTPNModuleFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Services && r.moduleMasterId === ModuleMasterIds.TPN && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //User Permission
  getUsermanagementPermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.UserManagement && r.moduleElementTransId === 0);
    return returnPermission;
  }

  getUsermanagementFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.UserManagement && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Announcement
  getAnnouncementFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.Announcement && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Collection Goal
  getCollectionGoalFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.CollectionGoal && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Collection Goal
  getDiagnosticCodeFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.DiagnosticCode && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Document Workflow
  getDocumentWorkflowFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.DocumentWorkflow && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Facility Documents
  getFacilityDocumentFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.FacilityDocuments && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Service Master
  getServiceMasterFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.ServiceMaster && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Service Type
  getServiceTypeFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.ServiceTypeMaster && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Facility Identifierd
  getFacilityIdentifiersFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.FacilityIdentifier && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Third Party Negotiation Master
  getThirdPartyFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.ThirdPartyMaster && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Payment Task
  getPaymentTaskFeaturePermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations && r.moduleMasterId === ModuleMasterIds.PaymentTask && r.moduleElementTransId > 0);
    return returnPermission;
  }

  //Report Permission
  getReportsPermission() {
    var myPermission = this.getPermissionStorageData();
    let returnPermission = myPermission.filter(r => r.sectionMasterId === ApplicationMenuSections.Reports && r.moduleElementTransId == 0);
    return returnPermission;
  }

  //#region Get current user role information
  getRoleTypeId() {
    let roleTypeId = this.getStorageItemsByName("roleTypeId");
    return roleTypeId;
  }

  isInternalOps = () => {
    let userRoleType = this.getRoleTypeId();
    if(userRoleType == RoleType.OperationHead || userRoleType == RoleType.SuperAdmin){
      return true;
    }
    return false;
  }

  isManager = () => {
    let userRoleType = this.getRoleTypeId();
    if(userRoleType == RoleType.Manager || userRoleType == RoleType.SuperAdmin){
      return true;
    }
    return false;
  }
  
  isTeamLead(){
    let userRoleType = this.getRoleTypeId();
    if(userRoleType == RoleType.Lead || userRoleType == RoleType.SuperAdmin){
      return true;
    }
    return false;
  }

  isSpecialist() {
    let userRoleType = this.getRoleTypeId();
    if(userRoleType == RoleType.Specialist || userRoleType == RoleType.SuperAdmin){
      return true;
    }
    return false;
  }
  //#endregion

  getLoggedInUserDetail = () => {
    return this.getStorageItemsByName("all");
  }

  checkIsAgreedAgreement = () => {
    return this.getStorageItemsByName("isAgreed");
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  patternPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (control.value) {

        const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')
        const valid = regex.test(control.value);
        if (!valid) {
          return { invalidPassword: true };
        }
      }
    };
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  MustNotMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      // set error on matchingControl if validation fails
      if (control.value == matchingControl.value) {
        matchingControl.setErrors({ mustNotMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  convertToTitleCase(str: any) {
    str = str.toLowerCase();
    str = str.split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    // Step 4. Return the output
    return str.join(' ');
  }

  getUrlValidation(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (control.value) {
        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
        const valid = regex.test(control.value);
        if (!valid) {
          return { invalidUrl: true };
        }
      }
    };
  }

  getNameValidation(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (control.value) {
        const regex = new RegExp('^[a-zA-Z \-\']+')
        const valid = regex.test(control.value);
        if (!valid) {
          return { "invalidName": true };
        }
      }
    };

  }

  doNotAllowNumber() {
    return '^[a-zA-Z \-\']+';
  }
  getPhoneValidation() {
    return "^[0-9]*$";
  }
  getPhoneValidations() {
    return /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
  }

  getUtcDate(currentDate: any) {
    return new Date(moment.utc(moment.utc(currentDate).local().format('MM/DD/YYYY')).local().toString())
  }
  getUtcDateTime(currentDate: any) {
    return new Date(moment(moment.utc(currentDate).local().format('MM/DD/YYYY hh:mm a')).toISOString());
  }
  getUtcDateOnly(currentDate: any) {
    return moment.utc(moment.utc(currentDate).local().format('MM/DD/YYYY')).format('L');
  }

  getUtcDateOnlyWithFormat(currentDate: any, dateFormat: string) {
    return moment.utc(moment.utc(currentDate).local(false)).format(dateFormat);
  }

  public FormatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return "0 Bytes";
    }

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  headerUserName = new Subject<string>();
  public passHeaderUserName(headerTitle: string) {
    this.headerUserName.next(headerTitle);
  }

  dropDownPositionUpdate(elemArray: any) {
    if (!!bootstrap) {
      for (const index in elemArray) {
        var dropdown = new bootstrap.Dropdown(elemArray[index], {
          popperConfig: function (defaultBsPopperConfig: any) {
            const newPopperConfig = {
              ...defaultBsPopperConfig,
              strategy: 'fixed',
            };
            return newPopperConfig;
          }
        })
      }
    }
  }

  downloadFile(url, fileName) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  // Use for clear reactive form validation

  public removeValidators(form: FormGroup) {
    for (const key in form.controls) {
      if (this.isNullOrUndefined(form.get(key).value) || form.get(key).value === '' || form.get(key).value === "") {
        form.get(key).clearValidators();
        form.get(key).updateValueAndValidity();
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, (month + 1), 0).getDate();
  }
  getCurrentWeek() {
    var currentDate = moment();
    var weekStart = currentDate.clone().startOf('isoWeek');
    var weekEnd = currentDate.clone().endOf('isoWeek');
    var days = [];
    for (var i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format("MM/DD/YYYY"));
    }
  }

  getLastWeek() {

    var currentDate = moment();
    var weekStart = moment().startOf('week').isoWeekday(1);
    var weekEnd = moment().startOf('week').isoWeekday(0);;
    var days = [];
    for (var i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format("MM/DD/YYYY"));
    }
    return days;
  }

  getWeekStartDate() {
    var currentDate = moment();
    var weekStart = currentDate.clone().startOf('isoWeek');
    return weekStart.toDate();
  }

  getWeekEndDate() {
    var currentDate = moment();

    var weekStart = currentDate.clone().endOf('isoWeek');
    return weekStart.toDate();
  }

  getMonthStartDate() {
    var currentDate = moment();
    var weekStart = currentDate.clone().startOf('month');
    return weekStart.toDate();
  }

  getMonthEndDate() {
    var currentDate = moment();

    var weekStart = currentDate.clone().endOf('month');
    return weekStart.toDate();
  }

  getYearStartDate() {
    var currentDate = moment();
    var weekStart = currentDate.clone().startOf('year');
    return weekStart.toDate();
  }

  getYearEndDate() {
    var currentDate = moment();
    var weekStart = currentDate.clone().endOf('year');
    return weekStart.toDate();
  }

  getLastWeekStartDate() {
    var currentDate = moment();
    // Use startOf 'isoWeek' to show week as MON to SUN
    // Use startOf 'week' to show week as SUN to SAT
    var weekFirstDay = currentDate.subtract(1, 'weeks').startOf('isoWeek');
    return weekFirstDay.toDate();
  }
  getLastWeekEndDate() {
    var currentDate = moment();
    // Use startOf 'isoWeek' to show week as MON to SUN
    // Use startOf 'week' to show week as SUN to SAT
    var weekLastDay = currentDate.subtract(1, 'weeks').endOf('isoWeek');
    return weekLastDay.toDate();
  }
  getLastMonthStartDate() {
    var currentDate = moment();
    var monthFirstDay = currentDate.subtract(1, 'months').startOf('month');
    return monthFirstDay.toDate();
  }
  getLastMonthEndDate() {
    var currentDate = moment();
    var monthLastDay = currentDate.subtract(1, 'months').endOf('month');
    return monthLastDay.toDate();
  }
  getLastYearStartDate() {
    var currentDate = moment();
    var yearFirstDay = currentDate.subtract(1, 'year').startOf('year');
    return yearFirstDay.toDate();
  }
  getLastYearEndDate() {
    var currentDate = moment();
    var yearLastDay = currentDate.subtract(1, 'year').endOf('year');
    return yearLastDay.toDate();
  }
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  public SetLoginServiceInformation(loginInfo: LoginServices) {
    this.LoginServiceInfo = loginInfo;
  }

  public GetLoginServiceInformation() {
    return this.LoginServiceInfo;
  }

  public GetEncodedValue(stringToEncode: any) {
    return btoa(stringToEncode);
  }

  public GetDecodedValue(encodedText: any) {
    return atob(encodedText);
  }

  under18ValidationMessage: boolean = false;
  public under18Validation(birthDateControl: Date): void {
    if (birthDateControl) {
      let today = new Date();
      let age = today.getFullYear() - birthDateControl.getFullYear();
      const monthDiff = today.getMonth() - birthDateControl.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateControl.getDate())) {
        age--;
      }
      if (age < 18) {
        this.under18ValidationMessage = true;
      }
      else {
        this.under18ValidationMessage = false;
      }
    }
    else {
      this.under18ValidationMessage = false;
    }
  }
}
